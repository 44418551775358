import React from 'react';
import styled from 'styled-components';

// Minimal version of our navbar
// JSON: "navbarType": "simple"

const Navbar = styled.nav`
  background-color: rgba(255, 255, 255) !important;
  border-bottom: 1px solid #e6e6e6;
  margin: 0;
  padding: 8px 16px;
  position: sticky;
  top: 0;
  z-index: 2147483646;

  & .navbar-brand {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    & img {
      height: 32px;
    }
  }
`;

function TopSimpleNavBarV6(data) {
  const { navbarBackground } = data;
  return (
    <Navbar
      className="navbar navbar-expand-xl"
      navbarBackground={navbarBackground}
    >
      <a className="navbar-brand" href="/">
        <div>
          <img
            src="https://voyager.postman.com/logo/postman-logo-orange.svg"
            alt="Postman"
          />
        </div>
      </a>
    </Navbar>
  );
}

export default TopSimpleNavBarV6;
