import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BaseButton } from 'aether-marketing';
// Minimal version of our navbar
// JSON: "navbarType": "postcon"

const Navbar = styled.nav`
  background-color: #080011;
  padding: 12px 0 16px 0;

  .navbar-nav ul {
    justify-content: center;
  }
  .active {
    color: white !important;
  }
  .nav-link {
    color: #7d7c7d;
    font-size: 20px;
    font-weight: 500;
  }
  @media (min-width: 900px) {
    .nav-link:not(:last-child) {
      padding-right: 60px;
    }
  }
  .nav-link:hover {
    color: white;
  }
  .navbar-toggler-icon {
    width: 24px;
    margin: auto 0;
  }
  button.navbar-toggler span.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(249, 244, 245)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  span.navbar-toggler-icon {
    background-image: none !important;
  }
`;

function PostconNavBar() {
  const [activeId, setActiveId] = useState('');
  useEffect(() => {
    const currentPath = window.location.pathname;
    const currentHash = window.location.hash;
    setActiveId(currentPath + currentHash);
  }, []);

  return (
    <Navbar className="navbar navbar-expand-lg sticky-top d-flex justify-content-between align-items-center">
      <div className="navbar-nav col-6  col-lg-2 align-items-start ">
        <a className="navbar-brand" href="/">
          <img
            src="https://voyager.postman.com/logo/postman-orange-and-white-logo-horizontal.svg"
            alt="Postman Logo"
            className="img-fluid"
            width="125"
          />
        </a>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div
        className="col-lg-8 collapse navbar-collapse justify-content-center mb-3 mb-lg-0"
        id="navbarNavDropdown"
      >
        <div className="row  justify-content-center">
          <a
            className={`nav-link ${activeId === '/postcon/' ? 'active' : ''}`}
            href="/postcon/"
          >
            About
          </a>
          <a
            className={`nav-link ${
              activeId === '/postcon/conference/' ? 'active' : ''
            }`}
            href="/postcon/conference/"
          >
            Conference
          </a>
          <a
            className={`${
              activeId === '/postcon/speakers/' ? 'active' : ''
            } nav-link`}
            href="/postcon/speakers/"
          >
            Speakers
          </a>
        </div>
      </div>

      <div
        className="collapse navbar-collapse col-lg-2 justify-content-end pr-0 text-center text-lg-right"
        id="navbarNavDropdown"
      >
        <BaseButton
          buttonType="primary"
          as="a"
          src="/postcon/#postcon-25"
          target="new-tab-external-nofollow"
          id="register-button"
          gaCategory="register-button"
          gaLabel="register-button"
          className=""
        >
          Notify me
        </BaseButton>
      </div>
    </Navbar>
  );
}

export default PostconNavBar;
