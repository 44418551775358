const CopyCodeButton = () => {
  // create button in codeblock
  document
    .querySelectorAll('pre > code')
    .forEach(function generateCopyButton(code) {
      const button = document.createElement('button');
      button.className = 'copy-code-button';
      button.type = 'button';
      button.innerText = 'Copy';
      // copy code to clipboard and apply text and styling to button
      button.addEventListener('click', function setCopyInClipboard() {
        navigator.clipboard.writeText(code.innerText).then(
          function start() {
            button.blur();
            button.classList.add('copy-in-progress');
            button.innerText = 'Copied';
            setTimeout(function complete() {
              button.classList.remove('copy-in-progress');
              button.innerText = 'Copy';
            }, 5000);
          },
          function error() {
            button.innerText = 'Error';
          }
        );
      });
      const pre = code;
      code.parentNode.insertBefore(button, pre);
    });
};

export default CopyCodeButton;
