import React, { useState } from 'react';
import styled from 'styled-components';
/* eslint-enable */

import { Divider, SectionStyles, FooterLinkStyles } from 'aether-marketing';
import bffFooterData from '../../../bff-data/footer.json';

import PreFooterSideBySideLottie from './PreFooterSideBySideLottie';

/* eslint-disable import/no-extraneous-dependencies */
const pmRr = require('@postman/rr');
const pmUuid = require('@postman/uuid');
/* eslint-enable */ // root relative link

// import footerData from '../../../build/footerDev.json';
const footerData = pmRr(bffFooterData);

// For local Footer TESTING
// import { useEffect } from 'react';
// import footerDataLocal from '../../../build/footerDev.json'; // absolut links

const Column = styled.ul`
  padding-left: 0;
`;

const ColumnRow = styled.li`
  list-style-type: none;
  margin-bottom: 0;
  padding: 5px 0;
`;

const FooterImg = styled.span`
  @media (min-width: 768px) {
    img {
      filter: grayscale(100%);
      transition: ${(props) => props.theme.transitions.all};
      &:hover {
        filter: grayscale(0);
        transition: ${(props) => props.theme.transitions.all};
      }
    }
  }
`;

const Copyright = styled.span`
  color: ${(props) => props.theme.colors.grey_50} !important;
  font-size: 1.2rem;
`;

const FooterColTitle = styled.h2`
  font-size: 1.6rem !important;
  line-height: 1.4;
  margin-bottom: 8px;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey_50};
  font-feature-settings: 'calt' 0;
`;

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};
const runtime = typeof document === 'object';
// Helper function for rel attribute in link or button
export function relStringGenerator(target) {
  if (target === 'new-tab-external-company') {
    return 'noopener noreferrer';
  }
  if (target === 'new-tab-external-nofollow') {
    return 'noopener noreferrer nofollow';
  }
  if (target === 'new-tab-postman') {
    return 'noopener';
  }
  return null;
}

// Helper function for target attribute in link or button
export function targetStringGenerator(target) {
  if (
    target === 'new-tab-external-company' ||
    target === 'new-tab-external-nofollow' ||
    target === 'new-tab-postman'
  ) {
    return '_blank';
  }
  return null;
}

function Footer() {
  const [data] = useState(footerData);

  // FOR LOCAL TOP NAVBAR TESTING: comment in navbarDataLocal import and below
  // **************************************************************************
  // const [data, setData] = useState(footerDataLocal)
  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'development') {
  //     setData(footerDataLocal); // pulls in footerData from file generated by BFF
  //   }
  // }, [])

  const columns = data.items.slice(0, 5);
  const PreFooterData = {
    divider: false,
    layout: '1S',
    title: '2024 State of the API report is here!',
    isDark: true,
    backgroundColor: '#0D1629',
    body: [
      "<p >Discover key API trends from 5,600+ devs and API professionals in Postman's annual report.</p>"
    ],
    link: {
      url: '/state-of-api/2024/',
      target: 'same-postman',
      id: 'state-of-api-2024',
      ariaLabel: 'Read the report!',
      gaCategory: 'postcon',
      gaLabel: 'state-of-api-2024',
      label: 'state-of-api-2024',
      text: 'Read the report'
    },
    media: {
      src: 'https://voyager.postman.com/illustration/state-of-api/postman-state-of-api-2024-illustration.svg',
      alt: '',
      border: '',
      isLottie: false,
      autoplay: true,
      loop: true
    }
  };

  const currentPage = runtime && window.location.pathname; // Get the current page

  const excludedRoutes = [
    '/pricing/',
    '/lp/email-unsubscribe/',
    '/lp/postman-enterprise/',
    '/lp/api-management/',
    '/lp/case-study-paypal/',
    '/lp/total-economic-impact/',
    '/lp/api-security/',
    '/lp/api-first-enterprise/',
    '/platform/',
    '/publish/',
    '/events/',
    '/lp/grow-with-postman/',
    '/events/',
    '/publish/',
    '/lp/grow-with-postman-enterprise/',
    '/lp/enterprise-api-documentation/',
    '/lp/enterprise-api-development/',
    '/lp/enterprise-api-testing/',
    '/lp/enterprise-api-collaboration/',
    '/lp/enterprise-api-security/',
    '/lp/enterprise-api-governance/',
    '/lp/enterprise-api-management/',
    '/lp/enterprise-brand/',
    '/report/state-of-api-2024/'
  ];

  if (runtime && !excludedRoutes.includes(currentPage)) {
    setTimeout(() => {
      const selectedPreFooterSideBySideLottie = document.querySelector(
        'section[class^="PreFooterSideBySideLottie"]'
      );

      if (selectedPreFooterSideBySideLottie) {
        selectedPreFooterSideBySideLottie.style.display = 'block';
      }
    }, 1000);
  }
  return (
    <>
      <PreFooterSideBySideLottie
        backgroundColor={PreFooterData.backgroundColor}
        layout={PreFooterData.layout}
        body={PreFooterData.body}
        link={PreFooterData.link}
        title={PreFooterData.title}
        // logo={PreFooterData.logo}
        // button={PreFooterData.button}
        media={PreFooterData.media}
        isDark={PreFooterData.isDark}
      />
      {/* <SideXSide

      /> */}
      <Divider fullWidth />
      <footer>
        <SectionStyles id="Footer">
          <div className="container small">
            <div className="row">
              <div className="col-sm-8 offset-sm-2 col-md-12 offset-md-0">
                <div className="row">
                  {/* First column */}
                  <div className=" col-8 offset-2 col-md-3 offset-md-0 col-lg-2 order-12 order-md-0 pad-md-right align-self-center">
                    <FooterImg>
                      <img
                        className="img-fluid col-12 col-sm-9 col-md-12 mb-5 d-block mx-auto"
                        src={data.src}
                        alt={data.alt}
                        fetchpriority="low"
                        loading="lazy"
                      />
                    </FooterImg>
                    <Copyright className="col-12 d-block text-center">
                      {data.copyright}
                    </Copyright>
                  </div>
                  {/* Second column */}
                  <div className="col-6 col-md-2 order-2 order-md-3 offset-lg-2 mb-2 mb-md-0">
                    {columns.slice(0, 1).map((item) => (
                      <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                        <FooterColTitle id={item.arialabelledby}>
                          {item.title}
                        </FooterColTitle>
                        <Column>
                          {(item.items &&
                            item.items.map((link) => (
                              <ColumnRow key={pmUuid()}>
                                <FooterLinkStyles
                                  href={link.url}
                                  rel={relStringGenerator(link.target)}
                                  target={targetStringGenerator(link.target)}
                                  aria-label={link.ariaLabel}
                                  onClick={() => {
                                    triggerGA(link.category, link.label);
                                  }}
                                >
                                  {link.title}
                                </FooterLinkStyles>
                              </ColumnRow>
                            ))) ||
                            ''}
                        </Column>
                      </nav>
                    ))}
                  </div>
                  <div className="col-6 col-md-2 order-2 order-md-3">
                    {/* Third column - stacked - top */}
                    {columns.slice(1, 2).map((item) => (
                      <div key={pmUuid()}>
                        <nav
                          aria-labelledby={item.arialabelledby}
                          style={{ marginBottom: `${37}px` }}
                        >
                          <FooterColTitle id={item.arialabelledby}>
                            {item.title}
                          </FooterColTitle>
                          <Column>
                            {(item.items &&
                              item.items.map((link) => (
                                <ColumnRow key={pmUuid()}>
                                  <FooterLinkStyles
                                    href={link.url}
                                    rel={relStringGenerator(link.target)}
                                    target={targetStringGenerator(link.target)}
                                    aria-label={link.ariaLabel}
                                    onClick={() => {
                                      triggerGA(link.category, link.label);
                                    }}
                                  >
                                    {link.title}
                                  </FooterLinkStyles>
                                </ColumnRow>
                              ))) ||
                              ''}
                          </Column>
                        </nav>
                      </div>
                    ))}
                    {/* Third column - stacked - bottom */}
                    {columns.slice(2, 3).map((item) => (
                      <div key={pmUuid()}>
                        <nav
                          aria-labelledby={item.arialabelledby}
                          className="mb-5"
                        >
                          <FooterColTitle id={item.arialabelledby}>
                            {item.title}
                          </FooterColTitle>
                          <Column>
                            {(item.items &&
                              item.items.map((link) => (
                                <ColumnRow key={pmUuid()}>
                                  <FooterLinkStyles
                                    href={link.url}
                                    rel={relStringGenerator(link.target)}
                                    target={targetStringGenerator(link.target)}
                                    aria-label={link.ariaLabel}
                                    onClick={() => {
                                      triggerGA(link.category, link.label);
                                    }}
                                  >
                                    {link.title}
                                  </FooterLinkStyles>
                                </ColumnRow>
                              ))) ||
                              ''}
                          </Column>
                        </nav>
                      </div>
                    ))}
                  </div>
                  {/* Fourth Column API Categories */}
                  <div className="col-6 col-md-2 order-3 order-md-4">
                    {columns.slice(3, 4).map((item) => (
                      <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                        <FooterColTitle id={item.arialabelledby}>
                          {item.title}
                        </FooterColTitle>
                        <Column>
                          {(item.items &&
                            item.items.map((link) => (
                              <ColumnRow key={pmUuid()}>
                                <FooterLinkStyles
                                  href={link.url}
                                  rel={relStringGenerator(link.target)}
                                  target={targetStringGenerator(link.target)}
                                  aria-label={link.ariaLabel}
                                  onClick={() => {
                                    triggerGA(link.category, link.label);
                                  }}
                                >
                                  {link.title}
                                </FooterLinkStyles>
                              </ColumnRow>
                            ))) ||
                            ''}
                        </Column>
                      </nav>
                    ))}
                  </div>
                  {/* Fifth column social */}
                  <div className="col-6 col-md-2 order-4 order-md-5">
                    <div className="row social">
                      {columns.slice(4, 5).map((item) => (
                        <div className="col-sm-12" key={pmUuid()}>
                          <nav
                            aria-labelledby={item.arialabelledby}
                            className="mb-5"
                          >
                            <FooterColTitle id={item.arialabelledby}>
                              {item.title}
                            </FooterColTitle>
                            <Column>
                              {(item.items &&
                                item.items.map((link) => (
                                  <ColumnRow key={pmUuid()}>
                                    <FooterLinkStyles
                                      href={link.url}
                                      rel={relStringGenerator(link.target)}
                                      target={targetStringGenerator(
                                        link.target
                                      )}
                                      aria-label={link.ariaLabel}
                                      onClick={() => {
                                        triggerGA(link.category, link.label);
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <img
                                          src={link.icon}
                                          style={{
                                            width: '16px',
                                            height: 'auto',
                                            marginRight: '1rem'
                                          }}
                                          alt={`${link.title} logo`}
                                          fetchpriority="low"
                                          loading="lazy"
                                        />
                                        <span>{link.title}</span>
                                      </div>
                                    </FooterLinkStyles>
                                  </ColumnRow>
                                ))) ||
                                ''}
                            </Column>
                          </nav>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  eslint-enable */}
          </div>
        </SectionStyles>
      </footer>
    </>
  );
}

export default Footer;
