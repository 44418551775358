import React from 'react';
import styled from 'styled-components';
import { BaseLink, Paragraph } from 'aether-marketing';

const runtime = typeof document === 'object';

const Container = styled.div`
background-color: #0D1629;

  min-height: 4.4rem;
  position: relative;
  z-index: 100;
`;

const Text = styled.div`
  text-align: center;
  padding: 1rem 5rem;
color: #EDEDED;
  & p {
    font-size: 14px !important;
    color: #EDEDED;
    // color: ${(props) => props.theme.colors.grey_90};
  }

  & a {
    display: inline;
    font-size: 14px !important;
    white-space: nowrap;
    color: #FF6C37;
    :hover {
    color: #FF6C37;
    border-bottom: 1px solid #FF6C37;
    text-decoration: none;
    }
  }
`;

const CloseButton = styled.span`
  position: absolute;
  right: 16px;
  top: 6px;
  background-repeat: no-repeat;
  cursor: pointer;

  & img {
    border-radius: ${(props) => props.theme.borderRadius.small};
    opacity: 0.6;
    padding: 8px;
    transition: ${(props) => props.theme.transitions.all};
  }

  & img:hover {
    background-color: #e6e6e6;
    opacity: 1;
    transition: ${(props) => props.theme.transitions.all};
  }
`;

const handleHelloClick = () => {
  const bar = document.getElementById('message-bar-alert-top');
  const track = bar.getAttribute('data-track');

  return (
    track &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', 'Hello bar', 'Click', track])
  );
};

class Hello extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBar: false,
      title: '',
      text: '',
      type: '',
      url: '',
      track: ''
    };
    // if user clicks x, disable hello bar and update local storage
    this.closeBar = (id) => {
      localStorage.setItem('hello', id);
      localStorage.setItem('hellocount', '3');
      this.setState({ showBar: false });
    };
    // open if bar count is less than three
    this.openBar = (id) => {
      const barValue = localStorage.getItem('hello');
      let barCount = JSON.parse(localStorage.getItem('hellocount'));

      if (barValue !== id) {
        barCount += 1;
        if (barCount > 3) {
          this.closeBar(id);
        } else {
          localStorage.setItem('hellocount', JSON.stringify(barCount));
          this.setState({ showBar: true });
        }
      } else {
        this.setState({ showBar: false });
      }
    };
  }

  componentDidMount() {
    if (runtime) {
      window.pmt('bff', [
        'hello',
        (d) => {
          const j = JSON.parse(d);
          const { data } = j;
          const keys = Object.keys(data);
          const max = keys.length;

          let allowed = false;
          let disallowed = false;
          let message;
          let i;
          let active;

          for (i = 0; i < max; i += 1) {
            const key = keys[i];
            const hello = data[key];

            if (hello.allow) {
              allowed = hello.allow.reduce(
                (returnValue, path) =>
                  (!returnValue &&
                    document.location.pathname.indexOf(path) !== -1) ||
                  returnValue,
                allowed
              );

              if (allowed && !active) {
                active = key;
                message = {
                  title: hello.title || j.title,
                  text: hello.text || j.text,
                  type: hello.type || j.type,
                  url: hello.url || j.url,
                  track: hello.track || j.track
                };
                if (hello.background) {
                  message.background = hello.background;
                }
              }
            } else if (hello.disallow) {
              disallowed = hello.disallow.reduce(
                (returnValue, path) =>
                  (!returnValue &&
                    document.location.pathname.indexOf(path) !== -1) ||
                  returnValue,
                disallowed
              );

              allowed = !disallowed;

              if (allowed && !active) {
                active = key;
                message = {
                  title: hello.title || j.title,
                  text: hello.text || j.text,
                  type: hello.type || j.type,
                  url: hello.url || j.url,
                  track: hello.track || j.track
                };
                if (hello.background) {
                  message.background = hello.background;
                }
              }
            }
          }
          if (message && allowed) {
            this.openBar(message.title);

            if (runtime && document.location.pathname !== message.url) {
              this.setState({
                title: message.title,
                text: message.text,
                type: message.type,
                url: message.url,
                track: message.track
              });
            }

            if (
              typeof message.background === 'string' &&
              message.background.charAt(0) === '#'
            ) {
              const messageBarAlertTop = document.getElementById(
                'message-bar-alert-top'
              );
              if (messageBarAlertTop) {
                messageBarAlertTop.style.cssText = `background-color: ${message.background}`;
              }
            }
          }
        },
        true
      ]);
    }
  }

  render() {
    const { showBar, text, title, type, url, track } = this.state;
    if (title && showBar === true) {
      return (
        <Container
          id="message-bar-alert-top"
          onClick={handleHelloClick}
          data-track={track}
        >
          <Text className="align-middle">
            <Paragraph
              className="mb-0 mr-2 d-inline"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {url && text && url.trim() !== '' && text.trim() !== '' && (
              <BaseLink
                onClick={() => this.closeBar(title)}
                target={type}
                src={url}
                linkType="arrowLink"
              >
                {text}
              </BaseLink>
            )}
          </Text>
          <CloseButton onClick={this.closeBar}>
            <img
              alt="X"
              src="https://voyager.postman.com/icon/x-icon-grey.svg"
            />
          </CloseButton>
        </Container>
      );
    }
    return null;
  }
}

export default Hello;
