import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import css from './css.json';

export const theme = {
  colors: css.colors,

  forms: css.forms,

  fonts: css.fonts,
  shadows: css.shadows,
  transitions: css.transitions,
  borderRadius: css.borderRadius,
  border: css.border
};

function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

Theme.propTypes = {
  children: PropTypes.node
};

Theme.defaultProps = {
  children: null
};
export default Theme;
