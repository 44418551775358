const manageDeviceId = (e) => {
  const hasAmpli = window.pmt && window.pmt('ampli');

  if (hasAmpli) {
    if (document.location.href.indexOf('device_id') === -1) {
      const eIsNotString = typeof e !== 'string';
      const { target } = e || {};
      const { parentNode } = target || {};
      const { href: directHref } = target || {};
      const { href: parentHref } = parentNode || {};
      const href = directHref || parentHref || e;
      const refKey = window.pmt('ampli').amplitude.getDeviceId();
      const delim = (href && href.indexOf('?') === -1 && '?') || '&';
      const refKeyUrl = `${href || e}${delim}ref_key=${refKey}`;
      const urlIsNonNull = refKeyUrl.indexOf('null') === -1;

      if (eIsNotString) {
        e.target.href = `${href}${delim}ref_key=${refKey}`;
      }

      return (urlIsNonNull && refKeyUrl) || e;
    }
  }

  return e;
};

export default manageDeviceId;
