const parseQuery = (query) => {
  const pairs = query.slice(1).split('&');
  const result = {};
  pairs.forEach((pair) => {
    const items = pair.split('=');
    result[items[0]] = decodeURIComponent(items[1] || '');
  });
  return result;
};

export default parseQuery;
